<template>
  <div>
    <TopNav></TopNav>
    <div class="first"></div>
    <div class="second">
      <!-- bounceInLeft -->
      <div class="second-box">
        <div class="second-box2 animated fadeInLeft">

          <img src="../static/img/scheme/scheme-top1.png" height="511" width="656"/>

        </div>
        <div class="second-box1 animated fadeInRight">
          <p class="txt-right txt-eng">REGIONAL</p>
          <p class="txt-right txt-eng">INTEGRATION OF SMART TCM</p>
          <div class="txt-line"></div>
          <p class="txt-right tit-right">区域整体中医药服务能力提升一体化解决方案</p>
          <div class="txt-cont">
            平台融合辅助诊断、智能开方、远程会诊、远程教育、知识库、共享药房等六大功能为核心，以区域中心医院为核心，进行区域一体化部署，全面覆盖基层医疗单位，以“互联网+中医”技术实现优质中医资源下沉，快速提升基层中医诊疗服务水平，让老百姓足不出村即可享受高质量的中医药服务、中医药配送到家服务、健康管理服务。
<!--            平台以中医院为中心，县、乡、村一体化部署，融合智能经典、智能快捷、远程会诊、远程教育、知识库和共享药房六大核心引擎，以“互联网+中医”技术实现中医优质资源下沉，快速提升基层中医诊疗水平，让老百姓足不出村即可享受到高质量的中医药服务、中药配送到家服务、健康管理服务。-->

<!--            经方云中医智能云服务平台是经方云科技创新研发的基于中医大数据和人工智能技术、中医辨证分析为核心的区域一体化辅助诊断平台。平台以中医院为中心，县、乡、村一体化部署，融合智能诊断、常见病诊断、远程会诊、远程教育、知识库和共享药房六大核心引擎，以“互联网+中医”技术实现中医优质资源下沉，快速提升基层中医诊疗水平，让老百姓足不出村即可享受到高质量的中医药服务、中药配送到家服务、健康管理服务。-->
          </div>
        </div>
      </div>
      <div v-show="!scroll1" style="width: 100%;height: 552px"></div>
      <div class="second-box bottom-box" v-show="scroll1">
        <div class="second-box3" ref="scrolla">
          <p class="txt-left txt-eng">5G INTELLIGENT</p>
          <p class="txt-left txt-eng">TRADITIONAL CHINESE</p>
          <p class="txt-left txt-eng">MEDICINE MUSEUM</p>
          <div class="txt-line"></div>
          <p class="txt-left tit-left">5G智能国医馆</p>
          <div class="txt-cont">
            经方云基于“中医 AI 大脑”打造的标准化5G智能国医馆，集智能经典、智能快捷、远程会诊、智慧共享药房、未病防治、患者健康管理、远程教育、区域大数据管理等功能为一体，全面实现优质中医资源下沉，利用大数据分析技术和人工智能技术，为用户提供：体质检测、智能诊断、远程会诊、健康管理等服务。通过品牌及运营帮扶，继承和发扬中医药在预防、保健、养生、康复方面的独特优势，发挥祖国传统医学优势，帮助基层的卫生服务站、中医诊所快速成长为高水平、有特色的中医专科诊疗机构，将预防、保健和治疗融为一体，为辖区居民提供优质的中医药服务，取得良好的社会效益。
          </div>
        </div>
        <div class="second-box4" ref="scrollb">

          <img src="../static/img/scheme/scheme-top2.png" height="401" width="621"/>
        </div>
      </div>
      <div class="second-box" v-show="scroll2">
        <div class="second-box2" ref="scrollc" style="margin-top: 150px">

          <img src="../static/img/scheme/scheme-top3.png" height="401" width="624"/>

        </div>
        <div class="second-box1" ref="scrolld">
          <p class="txt-right txt-eng">INTELLIGENT</p>
          <p class="txt-right txt-eng">CLOUD CLINIC OF TCM</p>
          <div class="txt-line"></div>
          <p class="txt-right tit-right" style="margin-bottom: 70px">智能中医云诊室</p>
          <div class="txt-cont">
            “智能中医云诊室”搭载“国医经方人工智能辅助诊断平台”，该平台通过辅助诊断、智能开方、远程会诊等功能辅助医生准确辨证论治，模拟名老中医辨证思维方式，提升普通医师中医诊疗服务能力。智能中医云诊室以“互联网+中医”实现优质中医药资源下沉，以AI、中医大数据、5G等信息技术打造专属智能中医云诊室，提高行业竞争力与知名度,帮助更多百姓解决病患问题。
<!--            “智能中医诊室”的核心是“国医经方人工智能辅助诊断平台”，该平台能够辅助医生准确辨证论治，提升普通中医师开具中药处方的质量及安全性。通过平台智能经典、智能快捷、远程会诊等功能进行智能辅助医生诊断，模拟张仲景的诊疗思维，即便普通的中医师，也能用疗效说话，充分赢得患者信任。同时平台也是一个高效的临证学习平台，能够帮助基层医生快速提高中医药诊疗水平，提升行业竞争力，打造专属智能中医诊室，帮助更多百姓解决病患问题。-->
<!--            “智能中医诊室”的核心是“经方云中医智能云服务平台”，云平台能够辅助医生准确辨证论治，提升普通中医师开具中药处方的质量及安全性。通过云平台智能经典、智能快捷、远程会诊等功能进行智能辅助医生诊断，模拟张仲景的诊疗思维，即便普通的中医师，也能用疗效说话，充分赢得患者信任。同时云平台也是一个高效的临证学习平台，能够帮助基层医生快速提高中医药诊疗水平，提升行业竞争力，打造专属智能中医诊室，帮助更多百姓解决病患问题 。-->
          </div>
        </div>
      </div>

    </div>
<!--    <div class="node-img1">-->
<!--      <div class="top-bg-2">-->
<!--        <img src="../static/img/home/foot-img.png" height="44" width="44"/>-->
<!--      </div>-->
<!--    </div>-->
    <div class="three">
      <div class="" ref="scrolle" v-show="scroll3">
        <div class="title">解决的问题</div>
        <div class="title-1">PROBLEM SOLVED</div>
        <div class="line"></div>
        <div class="con-1">平台将中医大数据与AI智能技术相结合，辅助医生开出高质量的处方，破解基层“无名医、无优质中医药服务”的难题。</div>
        <div class="con-1">利用物联网、5G技术打造区域智慧共享药房。使用道地药材，保证药材质量。减少药房建设成本、药房人员成本、药材损耗成本，将优质中医药资源下沉到基层最后一公里。</div>
        <div class="con-1">充分发挥祖国医学“治未病”的作用，继承和发扬中医药在预防、保健、养生、康复方面的独特优势，发挥祖国传统医学优势。</div>
        <div class="con-1">汇集海量中医药学知识、书籍、名医大师讲座视频等，帮助“西转中”医生进行中医基础学习、理论学习、临证学习等。</div>
        <img class="con-img1" src="../static/img/scheme/problem-1.png" height="54" width="55"/>
      </div>
    </div>
    <div class="three fourth">
      <div class="" ref="scrollf" v-show="scroll4">
        <div class="title">带来的效益</div>
        <div class="title-1">BENEFITS BROUGHT</div>
        <div class="con-1">区域中心医院：县乡村一体化部署，提升医院产能、提升整体中医药服务能力、提升中心医院区域影响力、提升患者就诊满意度。</div>
        <div class="con-1">国医馆及乡镇卫生院：利用名医效应，提升知名度及影响力；通过方证分析，名医帮扶等措施，提高医师医疗服务水平；智能诊断协助医生开方，提高诊所整体效益。</div>
        <div class="con-1">基层诊室：高质高效开展中医药服务及适宜技术服务，增加业务收入；提高知名度，留住当地患者，减少患者就诊时间。</div>
        <div class="con-1">社会整体：符合国家政策要求，帮助完成节约社保基金的任务；促进优质中医资源下沉，缓解基层看病难的现象；提升区域内的中医诊疗服务水平；促进区域内中医药产业升级。</div>
        <img class="con-img1" src="../static/img/scheme/problem-2.png" height="249" width="50"/>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import TopNav from "@/components/Nav/TopNav.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  name: "Scheme",
  data() {
    return {
      lists: [1, 2, 3],
      swiperOption: {},
      mySwiper: {},
      mySwiper1: {},
      currentIndex: 0,
      scroll1: false,
      scroll2: false,
      scroll3: false,
      scroll4: false,
      isChange: true
    };
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll,true)
  },
  methods: {
    changeIndex(index) {
      // if (this.isChange) {
      // return;
      // }
      this.currentIndex = index;
    },
    handleScroll(){
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop)
      if (scrollTop >= 600 && !this.scroll1) {
        this.scroll1 = true
        this.$refs.scrolla.classList.add('animated', 'fadeInLeft')
        this.$refs.scrollb.classList.add('animated', 'fadeInRight')
      }
      if (scrollTop >= 1200 && !this.scroll2) {
        this.scroll2 = true
        // this.$refs.scrollc.classList.add('animated', 'flipInY')
        // this.$refs.scrolld.classList.add('animated', 'flipInX')
        this.$refs.scrollc.classList.add('animated', 'fadeInLeft')
        this.$refs.scrolld.classList.add('animated', 'fadeInRight')
      }
      if (scrollTop >= 1800 && !this.scroll3) {
        this.scroll3 = true
        this.$refs.scrolle.classList.add('animated', 'flipInX')
      }
      if (scrollTop >= 2300 && !this.scroll4) {
        this.scroll4 = true
        this.$refs.scrollf.classList.add('animated', 'flipInX')
      }
    },

  },
  beforeDestroy() {
    window.removeEventListener('scroll',this.handleScroll,true)
  },
  watch: {
    currentIndex(val) {
      this.isChange = true;
    }
  },
  components: {
    TopNav,
    Footer
  }
};
</script>
<style lang="scss" scoped>
.first {
  width: 100%;
  height: 360px;
  margin-top: 80px;
  background: url("./../static/img/scheme/scheme-bg1.jpg") no-repeat center;
  //background-size: cover;
}
.second {
  position: relative;
  padding-top: 110px;
  padding-bottom: 80px;
  width: 1200px;
  margin: 0 auto;
  height: 2000px;
  text-align: center;
  //overflow: hidden;
  .second-box{
    display: flex;
    justify-content: space-between;
    align-items: first;
    .second-box1{
      width: 448px;
      .tit-right{
        min-height: 32px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 40px;
        margin-bottom: 30px;
      }
      .txt-right{
        text-align: right;
        clear: both;
      }
      .txt-eng{
        height: 30px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
      }
      .txt-line{
        width: 180px;
        height: 3px;
        background-color: #AE7B40;
        float: right;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .txt-cont{
        width: 448px;
        height: 209px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        text-align: justify;
      }
      .second-box1-2{
        color: #8091A5;
        font-size: 16px;
        text-indent: 40px;
        text-align: left;
        line-height: 30px;

      }
    }
    .second-box2{
      width: 656px;
    }
    .second-box3{
      width: 448px;
      .tit-left{
        height: 32px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
        margin-bottom: 30px;
      }
      .txt-left{
        text-align: left;
        clear: both;
      }
      .txt-eng{
        height: 30px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
      }
      .txt-line{
        width: 180px;
        height: 3px;
        background-color: #AE7B40;
        float: left;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .txt-cont{
        height: 430px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        text-align: justify;
      }
      .txt-cont1{
        width: 488px;
        height: 209px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        text-align: justify;
      }
    }
    .second-box4{
      width: 621px;
      margin-top: 120px;
    }
  }
  .bottom-box{
    margin-top: 120px;
  }
  .second_top {
    position: relative;
    height: 60px;
    margin-top: 60px;
    z-index: 9;
    .row {
      position: absolute;
      right: 10px;
      display: flex;
      justify-content: space-between;
      width: 640px;
      height: 100%;
      overflow: hidden;
      .top_btn {
        display: inline-block;
        width: 190px;
        height: 100%;
        line-height: 60px;
        font-size: 18px;
        text-align: center;
        color: #3565d7;
        background-image: url("./../static/image/applications/top_btn1.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        &:hover {
          cursor: pointer;
        }
      }
      .top_btn.active {
        background-image: url("./../static/image/applications/top_btn.png");
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .title {
    font-size: 30px;
    color: #191e4a;
    letter-spacing: 4px;
  }

  .table_box {
    position: absolute;
    top: 230px;
    left: 0;
    width: 100%;
    overflow: hidden;
    .person {
      position: relative;
      top: 20px;
      display: block;
      float: left;
      width: 500px;
      height: 550px;
      overflow: hidden;
      margin-top: 0px;
      // padding-left: 200px;
      img {
        width: 500px;
        height: 550px;
      }
    }
  }
  .table_right_box {
    width: 1200px;
    white-space: nowrap;
    margin: 0 auto;
  }

  .table_right {
    display: inline-block;
    width: 1200px;
    height: 556px;
    // margin: 0 auto;
    padding-left: 180px;
    padding-top: 30px;
    white-space: normal;
    .des {
      display: block;
      width: 640px;
      color: #5b607f;
      letter-spacing: 1.2px;
      text-align: left;
      line-height: 36px;
      float: right;
    }
    .row_des {
      display: block;
      width: 540px;
      height: 260px;
      margin-top: 30px;
      margin-right: 50px;
      float: right;
    }
  }
}
.three {
  width: 100%;
  min-width: 1200px;
  height: 529px;
  background: url("./../static/img/scheme/scheme-bg2.png") no-repeat center;
  //background-size: cover;
  padding-top: 63px;
  text-align: center;
  .title {
    width: 100%;
    text-align: center;
    height: 40px;
    font-size: 40px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }
  .title-1 {
    font-size: 24px;
    font-weight: 400;
    color: #B6B6C6;
    margin-bottom: 30px;
  }
  .con-1{
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }
  .line{
    width: 170px;
    height: 2px;
    background-color: #757575;
    margin: 0 auto 30px auto;
  }
  .con-img1{
    margin: 50px auto 0 auto;
  }
}
.fourth {
  width: 100%;
  min-width: 1509px;
  height: 703px;
  background: url("./../static/img/scheme/scheme-bg3.png") no-repeat center;
  //background-size: cover;
  text-align: center;
  padding-top: 0;
  margin: 70px auto 0 auto;
  .title{
    margin-top: 80px;
  }

}
.node-img1{
  width: 100%;
  height: 30px;
  text-align: center;
  position: relative;
  top: 10px;

}
</style>
